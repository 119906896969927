import React from 'react';
import Layout from 'Nori/Layout';
import styles from 'styles/pages/pageNotFound.module.scss';
import { NAV_UPDATES_JOB_CODE } from 'Custom/Footer/Footer.utils.js';

const HEAD = {
  title: '404 Error | Nexplanon.com',
  description:
    'Sorry, the page you are looking for cannot be found. Go back to the homepage at https://www.nexplanon.com/ to learn more.',
  keywords: '',
};

export default function PageNotFound() {
  return (
    <Layout head={HEAD} hideISI jobCode={NAV_UPDATES_JOB_CODE}>
      <div
        className={styles.wrapper}
        data-design-visible="visible_vd"
        data-design-type="module"
        data-design-nori_id="Error"
        data-design-module_name="Error"
        data-design-module_no="01"
      >
        <p className={styles.code}>404</p>
        <p className={styles.status}>Page Not Found</p>
      </div>
    </Layout>
  );
}
