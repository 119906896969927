import React, { isValidElement, useRef } from 'react';
import { GENERAL_JOB_CODE } from 'Custom/Footer/Footer.utils.js';
import BaseLayout from 'Nori/Layout/BaseLayout';
import Footer from 'Custom/Footer';
import Header from 'Custom/Header';
import StickyNavigation from 'Custom/Header/StickyNavigation';
import NurseNavigator from 'Custom/NurseNavigator';
import ISIFooter from 'Custom/ISI/ISIFooter';
import ISIBar from 'Custom/ISIBar';
import ExternalLinkModal from 'Custom/Modal/ExternalLinkModal';
import HealthcareModal from 'Custom/Modal/HealthcareModal';
import ISIModal from 'Custom/Modal/ISIModal';
import { FOCUS_TRACKING_CLASS } from 'Utils/hooks/useFocusAlwaysVisible';
import ModalPDF from 'Custom/ModalPDF';
import { useAppContext } from 'Providers/App';
import { UI_ELEMENTS } from 'Providers/App/elements';

const PageReference = () => null;
const Layout = ({
  reference: Reference = PageReference,
  className = '',
  hideISI = false,
  basic = false,
  jobCode = GENERAL_JOB_CODE,
  showChatbox = true,
  children,
  ...props
}) => {
  const { getElementState } = useAppContext();
  const isiRef = useRef(null);
  const pageableModalPDF = getElementState(UI_ELEMENTS.pageableModalPDF);

  return (
    <BaseLayout isiRef={isiRef} {...props}>
      <Header basic={basic} />
      {showChatbox && <NurseNavigator />}
      {!hideISI && <ISIBar />}
      <StickyNavigation basic={basic} />

      <div className={FOCUS_TRACKING_CLASS}>
        <main className={className}>{children}</main>
        {!hideISI && isValidElement(<Reference />) && (
          <ISIFooter reference={Reference} ref={isiRef} />
        )}
        <Footer basic={basic} jobCode={jobCode} />
      </div>

      <ISIModal />
      <ExternalLinkModal />
      <HealthcareModal />
      <ModalPDF paginator={pageableModalPDF} />
    </BaseLayout>
  );
};

export default Layout;
